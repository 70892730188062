import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { getDocument } from '@r-client/shared/util/core';
export function useRegionSwitcher(regionalSwitcherSettings) {
  var _getDocument;

  var location = (_getDocument = getDocument()) === null || _getDocument === void 0 ? void 0 : _getDocument.location;
  var variants = regionalSwitcherSettings.variants,
      activeVariantID = regionalSwitcherSettings.activeVariantID;

  var _useState = useState(null),
      proposedVariant = _useState[0],
      setVariant = _useState[1];

  var _useDisclosure = useDisclosure(),
      isOpen = _useDisclosure.isOpen,
      onOpen = _useDisclosure.onOpen,
      onClose = _useDisclosure.onClose;

  var onSetVariant = function onSetVariant(variant) {
    if (variant.id === activeVariantID) {
      return;
    }

    if (variant.needsConfirmation) {
      setVariant(variant.id);
      onOpen();
    } else {
      onConfirmVariant(variant.id);
    }
  };

  var onConfirmVariant = function onConfirmVariant(variantId) {
    var _variants$find;

    var _ref = (_variants$find = variants.find(function (v) {
      return v.id === variantId;
    })) !== null && _variants$find !== void 0 ? _variants$find : variants[0],
        url = _ref.url,
        onChange = _ref.onChange;

    if (onChange) {
      onChange();
    }

    if (location && url) {
      location.href = url;
    }
  };

  return {
    variants: variants,
    isModalOpen: !!proposedVariant && isOpen,
    proposedVariant: proposedVariant,
    activeVariantID: activeVariantID,
    onSetVariant: onSetVariant,
    onModalClose: onClose,
    onConfirmVariant: onConfirmVariant
  };
}