import _slicedToArray from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useGqlClient } from '@r-client/shared/data/client';
import { useAuth } from '@r-client/shared/feature/auth';
import '../graphql/get-crypto-wallets-query';
import { GetCryptoWalletsDocument } from '../graphql/types';
export var useHasWallet = function useHasWallet() {
  var _viewer$info2, _viewer$info2$investo, _cryptoWalletData$cry, _cryptoWalletData$cry2, _cryptoWalletData$cry3;

  var _useAuth = useAuth(),
      viewer = _useAuth.viewer;

  var client = useGqlClient();

  var _useLazyQuery = useLazyQuery(GetCryptoWalletsDocument, {
    client: client
  }),
      _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
      getCryptoWallets = _useLazyQuery2[0],
      _useLazyQuery2$ = _useLazyQuery2[1],
      cryptoWalletData = _useLazyQuery2$.data,
      cryptoWalletIsLoading = _useLazyQuery2$.loading,
      cryptoWalletQueryErrors = _useLazyQuery2$.error;

  useEffect(function () {
    if (viewer !== null && viewer !== void 0 && viewer.isAuthenticated) {
      var _viewer$info, _viewer$info$investor;

      getCryptoWallets({
        variables: {
          investorProfileId: viewer === null || viewer === void 0 ? void 0 : (_viewer$info = viewer.info) === null || _viewer$info === void 0 ? void 0 : (_viewer$info$investor = _viewer$info.investorProfile) === null || _viewer$info$investor === void 0 ? void 0 : _viewer$info$investor.id
        }
      });
    }
  }, [viewer === null || viewer === void 0 ? void 0 : viewer.isAuthenticated, viewer === null || viewer === void 0 ? void 0 : (_viewer$info2 = viewer.info) === null || _viewer$info2 === void 0 ? void 0 : (_viewer$info2$investo = _viewer$info2.investorProfile) === null || _viewer$info2$investo === void 0 ? void 0 : _viewer$info2$investo.id, getCryptoWallets]);
  var hasWallet = !cryptoWalletQueryErrors && !cryptoWalletIsLoading && !!(cryptoWalletData !== null && cryptoWalletData !== void 0 && (_cryptoWalletData$cry = cryptoWalletData.cryptoWallets) !== null && _cryptoWalletData$cry !== void 0 && _cryptoWalletData$cry.nodes.length); // A user may have an external wallet. We want to be sure there is a Republic wallet.

  var hasRepublicWallet = !!(cryptoWalletData !== null && cryptoWalletData !== void 0 && (_cryptoWalletData$cry2 = cryptoWalletData.cryptoWallets) !== null && _cryptoWalletData$cry2 !== void 0 && _cryptoWalletData$cry2.nodes.some(function (wallet) {
    return wallet.republicWallet;
  }));
  var hasExternalWallet = !!(cryptoWalletData !== null && cryptoWalletData !== void 0 && (_cryptoWalletData$cry3 = cryptoWalletData.cryptoWallets) !== null && _cryptoWalletData$cry3 !== void 0 && _cryptoWalletData$cry3.nodes.some(function (wallet) {
    return !wallet.republicWallet;
  }));
  return {
    hasWallet: hasWallet,
    hasRepublicWallet: hasRepublicWallet,
    hasExternalWallet: hasExternalWallet,
    cryptoWalletIsLoading: cryptoWalletIsLoading
  };
};