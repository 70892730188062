export var getTextColor = function getTextColor(theme) {
  var shade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '900';

  switch (theme) {
    case 'dark':
      return 'white';

    case 'light':
    case 'r-note-teaser':
    default:
      return "grey.".concat(shade);
  }
};