import { format, parseISO } from 'date-fns';
export var DATE_FORMAT = {
  "short": 'MM/dd/yy',
  shortWithYearsFull: 'MM/dd/yyyy',
  medium: 'MMMM d, yyyy',
  mediumTwoDigitsDay: 'MMM dd, yyyy',
  messageReceivedToday: 'hh:mm a',
  messageReceivedEarlier: "MMM dd yyyy 'at' hh:mm a",
  numericDateCommaTime: 'MM/dd/yyyy, hh:mm aaa',
  dateDotTimeWithMonthAbbreviation: 'MMM d, yyyy · hh:mm z',
  dateDotTimeWithMonthAbbreviationAndDay: 'EEEE, MMM d, yyyy · hh:mm z'
};
export var fromISOToShortDate = function fromISOToShortDate(isoDate) {
  if (isoDate === '') {
    return '';
  }

  var result = isoDate.split('T')[0];

  if (parseISO(result).toString() === 'Invalid Date') {
    throw new Error('Invalid time value');
  }

  return result;
};
export var fromShortDateToISO = function fromShortDateToISO(shortDate) {
  if (shortDate === '') {
    return '';
  }

  return new Date(shortDate).toISOString();
};
export var formatDate = function formatDate(date) {
  var template = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_FORMAT["short"];

  if (date === undefined) {
    return '';
  }

  if (typeof date === 'number') {
    var time = new Date(date * 1000);
    return format(time, template);
  }

  return format(date, template);
};