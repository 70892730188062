import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _AUTOSUGGEST_ITEMS_CO;

export var E_AUTOSUGGEST_ITEMS_LIST;

(function (E_AUTOSUGGEST_ITEMS_LIST) {
  E_AUTOSUGGEST_ITEMS_LIST["OFFERING"] = "offering";
  E_AUTOSUGGEST_ITEMS_LIST["USER"] = "user";
  E_AUTOSUGGEST_ITEMS_LIST["TAG"] = "tag";
})(E_AUTOSUGGEST_ITEMS_LIST || (E_AUTOSUGGEST_ITEMS_LIST = {}));

export var AUTOSUGGEST_ITEMS_CONTENT = (_AUTOSUGGEST_ITEMS_CO = {}, _defineProperty(_AUTOSUGGEST_ITEMS_CO, E_AUTOSUGGEST_ITEMS_LIST.OFFERING, {
  title: 'Deals'
}), _defineProperty(_AUTOSUGGEST_ITEMS_CO, E_AUTOSUGGEST_ITEMS_LIST.USER, {
  title: 'Users'
}), _defineProperty(_AUTOSUGGEST_ITEMS_CO, E_AUTOSUGGEST_ITEMS_LIST.TAG, {
  title: 'Categories'
}), _AUTOSUGGEST_ITEMS_CO);
export var E_OFFERING_STATUS_TYPES;

(function (E_OFFERING_STATUS_TYPES) {
  E_OFFERING_STATUS_TYPES["live"] = "live";
  E_OFFERING_STATUS_TYPES["comingSoon"] = "coming-soon";
  E_OFFERING_STATUS_TYPES["funded"] = "funded";
})(E_OFFERING_STATUS_TYPES || (E_OFFERING_STATUS_TYPES = {}));