import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var CreateRsvpDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation CreateRsvp(\n    $eventId: ID!\n    $fullName: String!\n    $email: Email!\n    $linkedinUrl: Url\n    $phoneNumber: PhoneNumber\n  ) {\n    eventsGuestsRsvpCreate(\n      input: {\n        guest: {\n          eventId: $eventId\n          fullName: $fullName\n          email: $email\n          linkedinUrl: $linkedinUrl\n          phoneNumber: $phoneNumber\n        }\n      }\n    ) {\n      errors {\n        message\n      }\n    }\n  }\n"])));
export var GetEventDetailsDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query GetEventDetails($slug: String!) {\n    event(slug: $slug) {\n      id\n      slug\n      title\n      body\n      bodySanitized\n      undisclosed\n      coverPhotoUrl(resize: { width: 576, height: 1024, type: auto })\n      beginAt\n      endAt\n      timezone\n      future\n      past\n      live\n      cta {\n        rsvp\n        text\n        url\n      }\n      category\n      useRsvp\n      url\n      address {\n        id\n        street1\n        street2\n        city\n        state\n        country\n        postalCode\n      }\n      xHosts {\n        id\n        position\n        bio\n        avatarUrl(resize: { width: 200, height: 200, type: auto })\n        firstName\n        lastName\n      }\n      offerings {\n        id\n        slug\n        companyName\n        description\n        cardImageUrl(resize: { width: 348, height: 361, type: auto })\n        cardTooltipText\n        logoUrl(resize: { width: 200, height: 200, type: auto })\n        state\n        following\n        indexInAllOfferings\n        numOfAllOfferings\n        badges\n        tags {\n          id\n          colorHex\n          displayName\n          slug\n        }\n      }\n      tags {\n        id\n        colorHex\n        displayName\n        slug\n      }\n    }\n  }\n"])));
export var GetEventsDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query GetEvents(\n    $timeFilters: EventsTimeFiltersInput\n    $states: [EventsStateEnum!]\n    $order: [EventsOrderEnum!]\n    $after: String\n    $first: Int\n    $slugs: [String!]\n  ) {\n    events(\n      timeFilters: $timeFilters\n      states: $states\n      order: $order\n      after: $after\n      first: $first\n      slugs: $slugs\n    ) {\n      edges {\n        cursor\n        node {\n          id\n          title\n          body\n          bodySanitized\n          slug\n          url\n          future\n          past\n          live\n          cta {\n            rsvp\n            text\n            url\n          }\n          beginAt\n          undisclosed\n          endAt\n          timezone\n          useRsvp\n          coverPhotoUrl(resize: { width: 576, height: 1024, type: auto })\n          category\n          address {\n            id\n            street1\n            street2\n            city\n            state\n            country\n            postalCode\n          }\n          xHosts {\n            id\n            avatarUrl(resize: { width: 100, height: 100, type: auto })\n            firstName\n            lastName\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"])));
export var GetTagEventsDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query GetTagEvents(\n    $timeFilters: EventsTimeFiltersInput\n    $states: [EventsStateEnum!]\n    $order: [EventsOrderEnum!]\n    $after: String\n    $first: Int\n    $slugs: [String!]\n    $tagSlug: String\n  ) {\n    events(\n      timeFilters: $timeFilters\n      states: $states\n      order: $order\n      after: $after\n      first: $first\n      slugs: $slugs\n      tagSlug: $tagSlug\n    ) {\n      edges {\n        cursor\n        node {\n          id\n          title\n          body\n          bodySanitized\n          slug\n          url\n          future\n          past\n          live\n          cta {\n            rsvp\n            text\n            url\n          }\n          beginAt\n          undisclosed\n          endAt\n          timezone\n          useRsvp\n          coverPhotoUrl\n          category\n          address {\n            id\n            street1\n            street2\n            city\n            state\n            country\n            postalCode\n          }\n          xHosts {\n            id\n            avatarUrl\n            firstName\n            lastName\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      totalCount\n    }\n  }\n"])));
export var GetUpcomingEventDocument = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query GetUpcomingEvent(\n    $timeFilters: EventsTimeFiltersInput\n    $states: [EventsStateEnum!]\n    $order: [EventsOrderEnum!]\n    $first: Int\n  ) {\n    events(\n      timeFilters: $timeFilters\n      states: $states\n      order: $order\n      first: $first\n    ) {\n      nodes {\n        id\n        address {\n          id\n          city\n        }\n        slug\n        title\n        coverPhotoUrl(resize: { width: 348, height: 182, type: fill })\n        beginAt\n        endAt\n        timezone\n        category\n        live\n      }\n    }\n  }\n"])));
export var GetUserReservationForEventDocument = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query getUserReservationForEvent($slug: String!) {\n    event(slug: $slug) {\n      id\n      userSubscribed\n    }\n  }\n"])));