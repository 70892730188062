import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var BannerDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query Banner($path: String!, $dismissedBanners: [BannerTypeEnum!]) {\n    banner(path: $path, dismissedBanners: $dismissedBanners) {\n      __typename\n      ... on NoteDistributionBanner {\n        user {\n          id\n        }\n      }\n      ... on NoteRewardClaimBanner {\n        timeLeft\n      }\n      ... on StateChangeConfirmationBanner {\n        waitlist\n        investment {\n          id\n          timeLeft\n          offering {\n            id\n            logoUrl\n            slug\n            type\n            companyName\n          }\n        }\n      }\n      ... on ConfirmEmailBanner {\n        user {\n          id\n          unconfirmedEmail\n          shouldPromptToResendConfirmationEmail\n        }\n      }\n      ... on ActionRequiredBanner {\n        investment {\n          id\n          state\n          investmentType\n          offering {\n            id\n            logoUrl\n            slug\n            type\n            companyName\n          }\n        }\n      }\n      ... on IdentityVerificationBanner {\n        user {\n          id\n          email\n          investorProfile {\n            id\n            cognitoFlowCustomerReference\n          }\n        }\n      }\n      ... on ClosingSoonBanner {\n        offeringClosingFirst {\n          id\n          slug\n          companyName\n        }\n        timeLeft\n        offerings {\n          id\n          logoUrl\n          slug\n          companyName\n        }\n      }\n    }\n  }\n"])));
export var UserEmailConfirmationResendDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation UserEmailConfirmationResend($clientMutationId: String) {\n    userEmailConfirmationResend(\n      input: { clientMutationId: $clientMutationId }\n    ) {\n      user {\n        id\n      }\n      errors {\n        message\n      }\n    }\n  }\n"])));