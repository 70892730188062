import _toConsumableArray from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { Email, Flash, Gear } from '@r-client/shared/ui/icons';
import Accredited from './images/logo-accredited.svg';
import Autopilot from './images/logo-autopilot.svg';
import Crypto from './images/logo-crypto.svg';
import NoteLogoFull from './images/logo-r-note.svg';
import NoteLogo from './images/logo-r-note-small.svg';
import RealEstate from './images/logo-real-estate.svg';
import VideoGames from './images/logo-video-games.svg';
import Wallet from './images/logo-wallet.svg';
import Startup from './images/startup.svg';
export var investNavItems = function investNavItems(isWalletCtaEnabled) {
  return [{
    href: '/companies',
    title: 'Startups',
    image: Startup,
    description: 'Invest in vetted private companies'
  }, {
    href: '/real-estate',
    title: 'Real estate',
    image: RealEstate,
    description: 'Build a global real estate portfolio'
  }, {
    href: '/culture',
    title: 'Culture',
    image: VideoGames,
    description: 'Invest in what inspires you'
  }, {
    href: '/crypto',
    title: 'Crypto',
    image: Crypto,
    description: 'Invest in blockchain tech and digital assets'
  }, {
    href: '/accredited',
    title: 'Deal Room',
    image: Accredited,
    description: 'Access the deal room for accredited investors'
  }, isWalletCtaEnabled ? {
    href: '/wallet',
    title: 'Wallet',
    image: Wallet,
    description: 'Store and manage your investments',
    trackingParams: {
      menu_entry: 'wallet',
      platform: 'web'
    }
  } : {
    href: '/autopilot',
    title: 'Autopilot',
    image: Autopilot,
    description: 'Build a diversified startup portfolio, hands-free'
  }, {
    href: '/note',
    title: 'Republic Note',
    image: NoteLogo,
    fullImage: NoteLogoFull
  }];
};
export var featuredInvestorsNavItem = {
  href: '/featured-investors',
  title: 'Featured investors'
};
export var secondariesMarketNavItem = {
  href: '/market',
  title: 'Buy & sell assets on the secondary market',
  mobileTitle: 'Buy & sell assets on the secondary market'
};
export var learnNavItems = [{
  href: '/about',
  title: 'About'
}, {
  href: '/learn/investors/how-it-works',
  title: 'How it works'
}, {
  href: '/investors/why-invest',
  title: 'Why invest'
}, {
  href: '/help/category/investing',
  title: 'FAQ'
}, {
  href: '/blog',
  nested: true,
  title: 'Blog',
  items: [{
    href: '/blog/newest',
    title: 'Newest'
  }, {
    href: '/blog/superheroes',
    title: 'Superheroes'
  }, {
    href: '/blog/crypto',
    title: 'Crypto'
  }, {
    href: '/blog/investor-education',
    title: 'Investor education'
  }, {
    href: '/blog/founder-resources',
    title: 'Founder resources'
  }, {
    href: '/blog/real-estate',
    title: 'Real estate'
  }]
}, {
  href: '/events',
  title: 'Events'
}];
export var raiseCapitalNavItems = [{
  href: '/raise',
  title: 'Raise capital'
}, {
  href: '/tokenization',
  title: 'Tokenization'
}, {
  href: '/crypto/services',
  title: 'Advisory services'
}, {
  href: '/sharedrops',
  title: "Sharedrops\u2122"
}, {
  href: '/partnerships',
  title: 'Partner network'
}, {
  href: '/blog/founder-resources',
  title: 'Blog'
}];
export var userNavItems = function userNavItems(_ref) {
  var userSlug = _ref.userSlug,
      mobile = _ref.mobile,
      isFounder = _ref.isFounder,
      hasWallet = _ref.hasWallet,
      republicWalletCta = _ref.republicWalletCta;
  return [{
    href: "/@".concat(userSlug),
    title: 'My profile'
  }, isFounder ? {
    href: '/campaigns',
    title: 'My campaigns'
  } : undefined, {
    href: "/portfolio",
    title: 'My portfolio'
  }, hasWallet ? {
    href: '/portfolio/crypto/assets',
    title: 'My wallet'
  } : republicWalletCta ? {
    href: '/wallet',
    title: 'My wallet',
    trackingParams: {
      menu_entry: 'wallet',
      platform: 'web'
    }
  } : undefined, {
    href: "/saved",
    title: 'Saved deals'
  }, {
    href: "/invite",
    title: 'Invite friends'
  }, {
    href: "/autopilot",
    title: 'Autopilot'
  }].concat(_toConsumableArray(mobile ? [{
    href: "/notifications",
    title: 'Notifications',
    icon: Flash
  }] : []), [{
    href: "/messages",
    title: 'Messages',
    icon: Email
  }, {
    href: "/settings",
    title: 'Settings',
    icon: Gear
  }, {
    href: '/logout',
    title: 'Logout'
  }]);
};