import { useAnalytics } from '@r-client/shared/data/analytics';
export var useHeaderMenuLinkTrack = function useHeaderMenuLinkTrack() {
  var analytics = useAnalytics();

  var track = function track(label, categoryName) {
    var category = categoryName.toLowerCase();
    analytics.track({
      name: 'header_menu_clicked',
      params: {
        label: label,
        category: category,
        platform: 'web'
      }
    });
  };

  return {
    track: track
  };
};