import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var getAutosuggestOpportunityData = function getAutosuggestOpportunityData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return (data || []).map(function (offering) {
    return {
      slug: offering.slug,
      type: 'offering',
      campaignStatus: offering.campaignStatus,
      text: offering.companyName,
      url: "/".concat(offering.slug) || '/',
      imageURL: offering.logoImageUrl || '/',
      imageURL2x: offering.logoImageUrl2x || '/'
    };
  });
};
export var getAutosuggestTagData = function getAutosuggestTagData(data) {
  return (data || []).map(function (tag) {
    return {
      slug: tag.slug,
      type: 'tag',
      url: "/tag/".concat(tag.slug),
      text: tag.displayName
    };
  });
};
export var getAutosuggestUserData = function getAutosuggestUserData(data) {
  return (data || []).map(function (user) {
    return {
      slug: user.slug,
      type: 'user',
      imageURL: user.avatarUrl || '',
      imageURL2x: user.avatarUrl2x || '',
      text: "".concat(user.firstName, " ").concat(user.lastName),
      url: "@".concat(user.slug)
    };
  });
};
export var collectAnalyticsAutosuggestOpportunityData = function collectAnalyticsAutosuggestOpportunityData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var slug = arguments.length > 1 ? arguments[1] : undefined;
  var offering = data === null || data === void 0 ? void 0 : data.find(function (item) {
    return item.slug === slug;
  });
  return {
    offering_id: offering === null || offering === void 0 ? void 0 : offering.id,
    offering_slug: offering === null || offering === void 0 ? void 0 : offering.slug,
    list_id: 'list-suggested-search'
  };
};
export var collectAnalyticsAutosuggestTagData = function collectAnalyticsAutosuggestTagData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var slug = arguments.length > 1 ? arguments[1] : undefined;
  var category = data === null || data === void 0 ? void 0 : data.find(function (item) {
    return item.slug === slug;
  });
  return {
    category_id: category === null || category === void 0 ? void 0 : category.id,
    category_name: category === null || category === void 0 ? void 0 : category.displayName,
    parent_category: (category === null || category === void 0 ? void 0 : category.parentName) || '',
    category_slug: category === null || category === void 0 ? void 0 : category.slug
  };
};
export var collectAnalyticsAutosuggestUserData = function collectAnalyticsAutosuggestUserData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var slug = arguments.length > 1 ? arguments[1] : undefined;
  var user = data === null || data === void 0 ? void 0 : data.find(function (item) {
    return item.slug === slug;
  });
  return {
    clicked_user_id: user === null || user === void 0 ? void 0 : user.id,
    clicked_user_slug: user === null || user === void 0 ? void 0 : user.slug
  };
};
export var handleSendSuggestionItemsAnalyticsEvent = function handleSendSuggestionItemsAnalyticsEvent(item, analytics, globalSearch, value) {
  // TODO: https://linear.app/republic/issue/INV-684/global-search-dropdown-events-not-tracked
  switch (item.type) {
    case 'offering':
      analytics.track({
        name: 'offering_clicked',
        params: _objectSpread({
          platform: 'web',
          search_term: value
        }, collectAnalyticsAutosuggestOpportunityData(globalSearch === null || globalSearch === void 0 ? void 0 : globalSearch.offerings, item.value))
      });
      break;

    case 'tag':
      analytics.track({
        name: 'category_clicked',
        params: _objectSpread({
          platform: 'web',
          search_term: value
        }, collectAnalyticsAutosuggestTagData(globalSearch === null || globalSearch === void 0 ? void 0 : globalSearch.tags, item.value))
      });
      break;

    case 'user':
      analytics.track({
        name: 'user_clicked',
        params: _objectSpread({
          platform: 'web',
          search_term: value
        }, collectAnalyticsAutosuggestUserData(globalSearch === null || globalSearch === void 0 ? void 0 : globalSearch.users, item.value))
      });
      break;
  }
};