// Modified from one of the solutions found here: https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export function getOrdinal(i) {
  var j = i % 10,
      k = i % 100;

  if (j === 1 && k !== 11) {
    return 'st';
  }

  if (j === 2 && k !== 12) {
    return 'nd';
  }

  if (j === 3 && k !== 13) {
    return 'rd';
  }

  return 'th';
}