import { useState } from 'react';
export var useSelectedNavigationEntry = function useSelectedNavigationEntry(defaultSelected) {
  var _useState = useState(defaultSelected),
      selectedNavigationEntry = _useState[0],
      setSelectedNavigationEntry = _useState[1];

  var onSelect = function onSelect(index) {
    setSelectedNavigationEntry(index !== undefined ? index : selectedNavigationEntry);
  };

  var onReset = function onReset() {
    setSelectedNavigationEntry(undefined);
  };

  return {
    selectedNavigationEntry: selectedNavigationEntry,
    onSelect: onSelect,
    onReset: onReset
  };
};