import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { typeCast } from './utils';
export function propsHelper(content, model, def) {
  if (content === undefined) {
    return def instanceof Function ? def() : def;
  }

  if (content === false) {
    return null;
  }

  if (content instanceof Function) {
    return content(model);
  }

  return content;
}
export function getCN(props, name) {
  if (!props) {
    return undefined;
  }

  var n = name !== null && name !== void 0 ? name : 'main';

  if (typeof props === 'string') {
    if (n === 'main') {
      return props;
    }
  }

  return props[n];
}
export function mergeCN(dest, // make main optional
source) {
  var result = source === undefined ? dest : typeof source === 'string' ? _objectSpread({
    main: source
  }, dest) : _objectSpread(_objectSpread({}, source), dest);
  return typeCast(result);
}