export var getPopperConfig = function getPopperConfig(isDesktop) {
  return {
    modifiers: [{
      name: 'offset',
      options: {
        offset: [100, -43]
      }
    }, {
      name: 'coords',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: function fn(_ref) {
        var state = _ref.state;
        state.styles.popper.transform = "translate3d(".concat(isDesktop ? 320 : 202, "px, ").concat(Math.round(state.rects.reference.y), "px, 0 )");
      }
    }]
  };
};