import { IFlipperEnum } from '@r-client/data/graphql-types';
import { useGetFeatureToggleQuery } from '@r-client/shared/data/flags/remote';
export var useWalletCtaFeatureFlag = function useWalletCtaFeatureFlag() {
  var _useGetFeatureToggleQ = useGetFeatureToggleQuery({
    variables: {
      feature: IFlipperEnum.RepublicWalletCta
    }
  }),
      walletCtaFeatureFlagData = _useGetFeatureToggleQ.data,
      isWalletCtaFeatureFlagDataLoading = _useGetFeatureToggleQ.isLoading;

  return !isWalletCtaFeatureFlagDataLoading && (walletCtaFeatureFlagData === null || walletCtaFeatureFlagData === void 0 ? void 0 : walletCtaFeatureFlagData.flipper.enabled);
};