import { E_LOGO_TYPES } from '@r-client/republic/feature/brand';
export var getLogo = function getLogo(theme) {
  switch (theme) {
    case 'dark':
      return E_LOGO_TYPES.White;

    case 'light':
    case 'r-note-teaser':
    default:
      return E_LOGO_TYPES.Black;
  }
};