import { useEffect, useState } from 'react';
import { typeCast } from '@r-client/shared/util/core';
export var useDelayedSwitcher = function useDelayedSwitcher(value, _ref) {
  var _ref$delayIn = _ref.delayIn,
      delayIn = _ref$delayIn === void 0 ? 0 : _ref$delayIn,
      _ref$delayOut = _ref.delayOut,
      delayOut = _ref$delayOut === void 0 ? 0 : _ref$delayOut;

  var _useState = useState(value),
      controlledValue = _useState[0],
      setControlledValue = _useState[1];

  var _useState2 = useState(value),
      resultValue = _useState2[0],
      setResultValue = _useState2[1];

  var setValue = function setValue(newValue, delay) {
    if (delay > 0) {
      return typeCast(setTimeout(function () {
        return setResultValue(newValue);
      }, delay));
    } else {
      setResultValue(newValue);
      return;
    }
  };

  useEffect(function () {
    var timerIn;
    var timerOut;

    if (controlledValue) {
      timerIn = setValue(controlledValue, delayIn);
    } else {
      timerOut = setValue(controlledValue, delayOut);
    }

    return function () {
      timerIn && clearTimeout(timerIn);
      timerOut && clearTimeout(timerOut);
    };
  }, [controlledValue, delayIn, delayOut]);
  return [resultValue, setControlledValue];
};