import { useRef } from 'react';
export var useNoPageScroll = function useNoPageScroll() {
  var previouslySetChecked = useRef(false);
  var pageScrollPreviouslyDisabled = useRef(false);

  var disablePageScroll = function disablePageScroll() {
    if (pageScrollPreviouslyDisabled.current) return;

    if (!previouslySetChecked.current && !!document.documentElement.style.overflow) {
      pageScrollPreviouslyDisabled.current = true;
    } else {
      previouslySetChecked.current = true;
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }
  };

  var enablePageScroll = function enablePageScroll() {
    if (pageScrollPreviouslyDisabled.current) return;
    document.documentElement.style.overflow = '';
    document.body.style.overflow = '';
  };

  return {
    disablePageScroll: disablePageScroll,
    enablePageScroll: enablePageScroll
  };
};