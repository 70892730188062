import { format } from 'date-fns';
import { DATE_FORMAT } from '@r-client/shared/util/core';
export function groupNotificationsByDate(notifications) {
  return notifications.reduce(function (acc, curr) {
    var currDate = format(new Date(curr.createdAt), DATE_FORMAT.mediumTwoDigitsDay);

    if (acc[currDate]) {
      acc[currDate].push(curr);
    } else {
      acc[currDate] = [curr];
    }

    return acc;
  }, {});
}