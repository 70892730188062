import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var GetNotificationsDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query GetNotifications(\n    $first: Int!\n    $after: String\n    $order: [NotificationOrderEnum!]\n  ) {\n    notifications(first: $first, after: $after, order: $order) {\n      edges {\n        cursor\n        node {\n          id\n          actorName\n          avatarUrl\n          targetName\n          targetEvent\n          action\n          actionEnding\n          description\n          seen\n          createdAt\n          xCtaUrl\n          xCtaText\n          showEntireDescription\n          actor {\n            isAdmin\n            slug\n          }\n        }\n      }\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n        endCursor\n      }\n    }\n  }\n"])));
export var NotificationsMarkAsSeenDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation NotificationsMarkAsSeen($ids: [ID!], $notificationLimit: Int) {\n    notificationsMarkAsSeen(\n      input: { ids: $ids, notificationLimit: $notificationLimit }\n    ) {\n      notifications {\n        id\n        actorName\n        targetName\n        targetEvent\n        action\n        actionEnding\n        description\n        seen\n        createdAt\n        xCtaUrl\n        xCtaText\n      }\n      errors {\n        message\n      }\n    }\n  }\n"])));
export var MarkHeaderNotificationsViewedDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation MarkHeaderNotificationsViewed(\n    $input: NotificationMarkHeaderViewedInput!\n  ) {\n    notificationsMarkHeaderViewed(input: $input) {\n      viewed\n      errors {\n        message\n      }\n    }\n  }\n"])));