export function getError(field) {
  return field.touched && !field.changing ? field.error : undefined;
}
export function handleChange(field, onChange) {
  var getValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (e) {
    return e.target.value;
  };
  var validateImmediately = arguments.length > 3 ? arguments[3] : undefined;
  return function (e) {
    field.value = getValue(e);
    if (!validateImmediately) field.changing = true;
    onChange === null || onChange === void 0 ? void 0 : onChange(e);
  };
}
export function handleTouched(field, baseHandler, isTouched) {
  return function (e) {
    var touched = isTouched === null || isTouched === void 0 ? void 0 : isTouched(e);

    if (touched === undefined) {
      field.touched = true;
    }

    if (typeof touched === 'boolean') {
      if (touched) {
        field.touched = true;
      }
    } else {
      touched === null || touched === void 0 ? void 0 : touched.then(function (isValue) {
        if (isValue) {
          field.touched = true;
        }
      });
    }

    field.changing = false;
    baseHandler === null || baseHandler === void 0 ? void 0 : baseHandler(e);
  };
}