import { teaserToEditableEntity } from './teaser-to-editable-entity';
export var getUserTeasers = function getUserTeasers(viewer) {
  var _viewer$info, _viewer$info$teaserPa;

  return viewer === null || viewer === void 0 ? void 0 : (_viewer$info = viewer.info) === null || _viewer$info === void 0 ? void 0 : (_viewer$info$teaserPa = _viewer$info.teaserPages) === null || _viewer$info$teaserPa === void 0 ? void 0 : _viewer$info$teaserPa.nodes;
};
export var teasersToEditableEntities = function teasersToEditableEntities(teasers) {
  if (!teasers) {
    return [];
  }

  return teasers.map(teaserToEditableEntity);
};