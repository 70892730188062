import { useMediaQuery as useMediaQuery$ } from 'react-responsive';
export var media = {
  sm: 768,
  md: 970,
  lg: 1170
}; // TODO: MK move hooks to separate ui library

var useMediaQuery = false ? function () {
  return true;
} : useMediaQuery$;
export var useMediaUpSm = function useMediaUpSm() {
  return useMediaQuery({
    query: "all and (min-width: ".concat(media.sm, "px)")
  });
};
export var useMediaUpMd = function useMediaUpMd() {
  return useMediaQuery({
    query: "all and (min-width: ".concat(media.md, "px)")
  });
};
export var useMediaUpLg = function useMediaUpLg() {
  return useMediaQuery({
    query: "all and (min-width: ".concat(media.lg, "px)")
  });
};
export var useMediaDownSm = function useMediaDownSm() {
  return useMediaQuery({
    query: "all and (max-width: ".concat(media.md - 1, "px)")
  });
};
export var useMediaDownXs = function useMediaDownXs() {
  return useMediaQuery({
    query: "all and (max-width: ".concat(media.sm - 1, "px)")
  });
}; // TODO: Find out why the useMediaDownXs is not working properly in JSX for time being I have create similar with function

export var useMobile = function useMobile() {
  return useMediaQuery$({
    query: "(max-width: ".concat(media.sm - 1, "px)")
  });
};
export var useDesktop = function useDesktop() {
  return useMediaQuery$({
    query: "(min-width: ".concat(media.sm, "px)")
  });
};
export var useMediaDownMd = function useMediaDownMd() {
  return useMediaQuery({
    query: "all and (max-width: ".concat(media.lg - 1, "px)")
  });
};
export var useMediaOnlySm = function useMediaOnlySm() {
  return useMediaQuery({
    query: "all and (min-width: ".concat(media.sm, "px) and (max-width: ").concat(media.md - 1, "px)")
  });
};
export var useMediaOnlyMd = function useMediaOnlyMd() {
  return useMediaQuery({
    query: "all and (min-width: ".concat(media.md, "px) and (max-width: ").concat(media.lg - 1, "px)")
  });
};
export var useMediaDown = function useMediaDown(width) {
  return useMediaQuery({
    query: "all and (max-width: ".concat(width, "px)")
  });
};