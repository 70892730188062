import get from 'lodash/get';
export var getAnalyticsBannerData = function getAnalyticsBannerData(data) {
  var _get, _get2, _get3, _data$banner;

  var companyName = (_get = get(data === null || data === void 0 ? void 0 : data.banner, 'offeringClosingFirst.companyName')) !== null && _get !== void 0 ? _get : get(data === null || data === void 0 ? void 0 : data.banner, 'investment.offering.companyName');
  var offeringSlug = (_get2 = get(data === null || data === void 0 ? void 0 : data.banner, 'offeringClosingFirst.slug')) !== null && _get2 !== void 0 ? _get2 : get(data === null || data === void 0 ? void 0 : data.banner, 'investment.offering.slug');
  var waitlist = get(data === null || data === void 0 ? void 0 : data.banner, 'waitlist');
  var daysLeft = (_get3 = get(data === null || data === void 0 ? void 0 : data.banner, 'timeLeft')) !== null && _get3 !== void 0 ? _get3 : get(data === null || data === void 0 ? void 0 : data.banner, 'investment.timeLeft');
  return {
    banner_name: data === null || data === void 0 ? void 0 : (_data$banner = data.banner) === null || _data$banner === void 0 ? void 0 : _data$banner.__typename,
    extra_data: {
      companyName: companyName,
      waitlist: waitlist,
      offeringSlug: offeringSlug,
      daysLeft: daysLeft
    }
  };
};